/* Mini CSS reset and basic typography  */
@import url('./properties.css');

:global * {box-sizing: border-box}
:global html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}
/* General settings */
:global body {
    margin: 0;
    font: 1em/1.6 system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Fira Sans, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: #111
}
:global img,
:global iframe {border: none; width: 100% }
/* Headlines */
:global h1,
:global h2,
:global h3,
:global h4,
:global h5,
:global h6 {margin: 0.6em 0; font-weight: normal}
:global h1 {font-size: 2.625em; line-height: 1.2}
:global h2 {font-size: 1.625em; line-height: 1.2}
:global h3 {font-size: 1.3125em;line-height: 1.24}
:global h4 {font-size: 1.1875em;line-height: 1.23}
:global h5,
:global h6 {font-size: 1em; font-weight:bold}
:global table {border-collapse: collapse;border-spacing: 0}
:global th,
:global td {text-align: left;vertical-align: top}

:global input,
:global select,
:global button {
    padding: 0.3em 0.7em;
    border: 1px solid rgba(0,0,0,0.5);
    border-radius: 4px;
    margin: 1em 1em 0 0;
    min-height: 27px;
}

:global input:focus {
    border: 1px solid rgba(0,0,0,1);
}

:global button {
    display:inline-block;
    color: #fff;
    background-color: #000;
    border:0.0em solid #fff;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    text-align:center;
    transition: all 0.2s;
}

:global button:hover {
    color:#fff;
    background-color:#000;
}

@media all and (max-width:30em){
    :global button {
        
    }
}

.page-block {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2em;
    text-align: left;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.textCenter {
    text-align: center;
}

.button-primary {

}

.button-secondary {

}


.internalPageWrap {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 1em;
}

.grabBirthdateWrap {
    display: flex;
}

.weekCard {
    border: 2px solid;
    border-radius: 8px;
    padding: 1em;
    margin-bottom: 1em;
}

.weekPercentage {
    color: white;
    background-color: black;
    text-align: right;
    padding-right: 0.5em;
    position: relative;
}

.weekPercentage:after {
    content: '%';
    position: absolute;
    left: calc(100% + 0.5em);
    top: 0;
    color: black;
    font-weight: bold;
}

.moreWeeksToCome {
    opacity: 0.7;
}

.firstButtons {
    justify-content: flex-start;
    display: flex;
}

.firstLine {
    margin-bottom: 3em;
}

.saveScreenBg {
    background: #19cade;
    background: var(--theme-color-background);
}

.stepNumber {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
}

.stepNumber span {
    background: #000;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
    font-size: 0.7em;
}